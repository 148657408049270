import './App.css';

function App() {
  return (
    <div className="App">
        <h1 className="title"> HOMEBREW.ART </h1>
        <p className="subtitle">ホームブリューデベロッパーの皆様、<br/>自分たちのプログラムやアートを収益化するための道がついに開かれました！</p>
    </div>
  );
}

export default App;
